<template>
  <wrapper-with-back class="device-info-page">
    <template v-if="loadingInProgress">
      <hs-heading key="loading-header" title="Device Information" class="device-info-page__title" />
      <div class="device-info-page__loader-cont">
        <i class="device-info-page__loader"></i>
      </div>
    </template>
    <template v-else>
      <hs-heading key="usual-header" title="Device Information" class="device-info-page__title">
        <template #title-elements>
          <div class="device-info-page__verification-status">
            <DeviceVerificationStatus
              :status="verificationStatus"
              :id="$route.params.id"
              :productLine="productLine"
              @deviceRemoved="handleDeviceRemoval"
            />
          </div>
        </template>
      </hs-heading>
      <DeviceInfo :claims="claims" ref="deviceInfo" size="large" @editingchanged="handleDirty" />
      <!-- claims BEGIN -->
      <div class="device-info-page__heading">
        <h2 class="device-info-page__title">Device Service History</h2>
        <GetServiceBtn
          :id="$route.params.id"
          :product-line="productLine"
          :claims="claims"
          :disabled="disableClaimBtn"
          size="md"
        />
      </div>
      <div class="device-info-page__claims">
        <div v-if="!isAnyClaim" class="device-info-page__empty-claims">
          No service request was initiated for this device
        </div>
        <DeviceClaim
          v-for="claim in claims"
          :key="claim.id"
          :claim="claim"
          class="device-info-page__claim"
          row
        />
      </div>
      <!-- claims END -->
      <!-- save changes popup BEGIN -->
      <hs-popup
        v-if="showSavePopup"
        aria-labelledby="save-modal-titile"
        aria-describedby="save-modal-desc"
        confirm-button-text="Keep changes"
        decline-button-text="Discard changes"
        @hide="closeSaveDialog"
        @decline="discardChanges"
        @confirm="saveChanges"
      >
        <h1 class="hs-popup__title" id="save-modal-titile">
          You have some unsaved changes on this page. Are you sure you want to leave?
        </h1>
        <div class="hs-popup__description">
          <p id="save-modal-desc">
            You made changes to this page and forgot to press the blue “Save” button.
          </p>
        </div>
      </hs-popup>
      <!-- save changes popup END -->
    </template>
  </wrapper-with-back>
</template>

<script>
import {mapState} from 'vuex';
import DeviceInfo from '@/components/device/info/DeviceInfo';
import DeviceClaim from '@/components/device/list/MyDeviceListClaim';
import GetServiceBtn from '@/components/shared/GetServiceBtn';
import DeviceVerificationStatus from '@/components/device/DeviceVerificationStatus';
import {allowClaims} from '@/constants/env';
import {isInProgressStatus, isNotEligibleForRepairStatus} from '@/constants/esb_claim_statuses';
import {smartphone} from '@/constants/device_product_lines';

export default {
  metaInfo: {
    titleTemplate: 'Device Information - %s',
  },
  components: {
    DeviceInfo,
    DeviceClaim,
    GetServiceBtn,
    DeviceVerificationStatus,
  },
  data() {
    return {
      to: null,
      dirty: false,
      showSavePopup: false,
      loadingInProgress: true,
    };
  },
  computed: {
    ...mapState('jobInfo', ['claimsList']),
    ...mapState('deviceInformation', ['equipmentStatus', 'productLine', 'serialNumber']),
    ...mapState('user', ['onlineClaimEligible', 'supportSmartphoneClaim']),
    allowSmartphoneClaim() {
      return this.productLine === smartphone ? this.supportSmartphoneClaim !== false : true;
    },
    serialNumber() {
      return this.$store.state.deviceInformation.serial_number;
    },
    claims() {
      let res = [];
      if (this.claimsList && this.claimsList.length) {
        res = this.claimsList.filter(
          (j) =>
            j?.claimDetails?.inventoryEquipmentID === this.$route.params.id &&
            j?.serviceJobStatus !== 'INITIATED'
        );
      }
      return res;
    },
    isAnyClaim() {
      return this.claims.length;
    },
    isAnyClaimInProgress() {
      return this.claims?.some(({serviceJobStatus}) => isInProgressStatus(serviceJobStatus));
    },
    // TODO: This is a workaround for making Request service button disabled.
    isAnyClaimWithNotEligible() {
      return this.claims?.some(({serviceJobSubStatusName}) =>
        isNotEligibleForRepairStatus(serviceJobSubStatusName)
      );
    },
    unverifiedDevice() {
      return this.verificationStatus && this.verificationStatus !== 'Active';
    },
    disableClaimBtn() {
      if (!allowClaims) {
        return true;
      }
      return (
        this.onlineClaimEligible === false ||
        this.isAnyClaimInProgress ||
        this.isAnyClaimWithNotEligible ||
        !this.allowSmartphoneClaim ||
        this.unverifiedDevice
      );
    },
    verificationStatus() {
      return this.equipmentStatus;
    },
  },
  methods: {
    // TODO: For the demo purposes only?
    handleDeviceRemoval() {
      const {query} = this.$route;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    handleDirty(val) {
      this.dirty = val;
      window.onbeforeunload = val ? this.beforeunloadHandler : null;
    },
    async saveChanges() {
      this.showSavePopup = false;
      const {deviceInfo} = this.$refs;
      await Promise.all([deviceInfo.updateDevice()]);
    },
    async discardChanges() {
      this.showSavePopup = false;
      this.forceNavigation = true;
      await this.$router.push(this.to);
    },
    closeSaveDialog() {
      this.showSavePopup = false;
      this.to = null;
    },
    beforeunloadHandler(e) {
      e.preventDefault();
      e.returnValue = '';
    },
  },
  async beforeCreate() {
    const {id} = this.$route.params;
    const {customerRef} = this.$route.query;
    const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
      customerRef,
    });
    await this.$store.dispatch('deviceInformation/GET_DEVICE_INFO', {
      id,
      customerRef: resp?.customerRef,
    });
    await this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
      customerRef: resp?.customerRef,
    });
    await this.$store.dispatch('myDevices/GET_MY_DEVICES', {
      customerRef: resp?.customerRef,
    });
    this.loadingInProgress = false;
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    this.$store.commit('deviceInformation/CLEAR_STORE');
  },
  beforeRouteLeave(to, from, next) {
    if (!this.dirty || this.forceNavigation) {
      next();
    } else {
      this.to = to;
      this.showSavePopup = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.device-info-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }
  &__title {
    margin-top: 20px;
    color: #000000;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;

    @include mf($gridMDBreakpoint) {
      margin-right: 0;
    }
  }
  &__heading-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 14px;
    line-height: 160%;
    &::before {
      display: block;
      width: 9px;
      height: 6px;
      margin-right: 4px;
      background: url(~@/assets/images/nav-back.svg) no-repeat 0 0/9px 6px;
      transform: rotate(90deg);
      content: '';
    }
  }
  &__heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 50px;

    @include mf($gridMDBreakpoint) {
      align-items: center;
      margin-top: 80px;
    }
  }
  &__claims {
    margin-top: 10px;
    border: solid $gray;
    border-width: 1px 0;
  }
  &__empty-claims {
    padding: 25px 0;
    color: $mediumGray;
  }
  &__verification-status {
    width: 100%;
    margin-top: 10px;
    font-weight: normal;

    @include mf($gridMDBreakpoint) {
      width: fit-content;
      max-width: 775px;
      margin-top: 0;
      margin-left: 15px;
    }
  }
  &__loader-cont {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  &__loader {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background-image: url('~@/assets/images/hs-loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
  }
}
</style>
<style lang="scss">
.device-info-page {
  .hs-heading__title-wrapper {
    display: block;

    @include mf($gridMDBreakpoint) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
