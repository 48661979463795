<template>
  <div class="device-info__row">
    <!-- delete popup BEGIN -->
    <hs-popup
      v-if="showRemovePopup"
      aria-labelledby="remove-modal-title"
      aria-describedby="remove-modal-desc"
      decline-button-text="Cancel"
      confirm-button-text="Remove Device"
      @confirm="confirmDelete"
      @hide="showRemovePopup = false"
    >
      <h1 class="hs-popup__title" id="remove-modal-title">
        Are you sure you want to remove this device?
      </h1>
      <div class="hs-popup__description">
        <p id="remove-modal-desc">
          Removing this device will erase all device information. Are you sure you want to proceed?
        </p>
      </div>
    </hs-popup>
    <!-- delete popup END -->
    <!-- empty serial number popup BEGIN -->
    <hs-popup
      v-if="isEmptySerialNumberPopupVisible"
      aria-labelledby="empty-serial-number-title"
      aria-describedby="empty-serial-number-desc"
      confirm-button-text="Continue editing"
      decline-button-text="Remove this device"
      @decline="confirmDelete"
      @confirm="hideEmptySerialNumberPopup"
      @hide="hideEmptySerialNumberPopup"
    >
      <h1 class="hs-popup__title" id="empty-serial-number-title">
        Please enter a valid serial number.
      </h1>
      <div class="hs-popup__description">
        <p id="empty-serial-number-desc">
          A device serial number is needed in order to request service.
        </p>
      </div>
    </hs-popup>
    <!-- delete popup END -->
    <div class="device-info__column device-info__column--first">
      <DeviceIcon
        :gray-border="unverifiedDevice"
        :icon="productLineName"
        class="device-info__device-icon"
      />
    </div>
    <div class="device-info__column" v-if="!showDesktop">
      <div class="device-info__label">Device Model</div>
      <div class="device-info__value">{{ modelName }}</div>
    </div>
    <div class="device-info__column" v-if="!showDesktop">
      <div class="device-info__date">
        Device added <br />
        <span>{{ createdDateShow }}</span>
      </div>
    </div>
    <div class="device-info__column" v-if="showDesktop">
      <div class="device-info__label">Device Model</div>
      <div class="device-info__value">{{ modelName }}</div>
      <div class="device-info__label">Device Nickname</div>
      <button
        v-if="equipmentName && !editNickName && !unverifiedDevice"
        class="device-info__value device-info__value--editable"
        @click="startEditNickname"
      >
        {{ equipmentName }}
      </button>
      <div v-if="equipmentName && !editNickName && unverifiedDevice" class="device-info__value">
        {{ equipmentName }}
      </div>
      <button
        v-if="!equipmentName && !editNickName"
        class="hs-link device-info__add"
        @click.prevent="editNickName = true"
      >
        Add Nickname
      </button>
      <div v-if="editNickName" class="device-info__field">
        <hs-input
          :error="nicknameError"
          :readonly="blockEditing"
          type="bottom-border"
          class="device-info__input"
          v-model="equipmentName"
          @input="$emit('editingchanged', true)"
        />
        <hs-button :disabled="blockEditing" size="xs" @click="updateDevice">Save</hs-button>
      </div>
    </div>
    <div class="device-info__column" v-if="showDesktop">
      <div class="device-info__label">Serial Number</div>
      <div v-if="serialNumber && !editSerialNumber">
        <button
          v-if="serialNumber && serialNumberChangeable && !unverifiedDevice"
          class="device-info__value device-info__value--editable"
          @click="startEditSerialNumber"
        >
          {{ serialNumber }}
        </button>
        <div class="device-info__value" v-else>
          {{ serialNumber }}
        </div>
      </div>
      <button
        v-if="!serialNumber && serialNumberChangeable && !editSerialNumber"
        class="hs-link device-info__add"
        @click.prevent="editSerialNumber = true"
      >
        Add Serial Number
      </button>
      <div v-if="editSerialNumber && serialNumberChangeable" class="device-info__field">
        <hs-input
          :error="snError"
          :readonly="blockEditing"
          type="bottom-border"
          class="device-info__input"
          @input="$emit('editingchanged', true)"
          v-model="serialNumber"
        />
        <hs-button :disabled="blockEditing" size="xs" @click="updateDevice">Save</hs-button>
      </div>
    </div>
    <div class="device-info__column device-info__column--last" v-if="showDesktop">
      <div class="device-info__date">
        Device added <br />
        {{ createdDateShow }}
      </div>
      <hs-button variant="secondary" v-if="!isAnyClaim" size="sm" @click="removePopup">
        Remove Device
      </hs-button>
    </div>
    <div class="device-info__column device-info__column--last" v-if="!showDesktop">
      <hs-button variant="secondary" v-if="!isAnyClaim" size="sm" @click="removePopup">
        Remove Device
      </hs-button>
    </div>
    <div class="device-info__column device-info__column--full" v-if="!showDesktop">
      <div class="device-info__label">Device Nickname</div>
      <button
        v-if="equipmentName && !editNickName && !unverifiedDevice"
        class="device-info__value device-info__value--editable"
        @click="startEditNickname"
      >
        {{ equipmentName }}
      </button>
      <div v-if="equipmentName && !editNickName && unverifiedDevice" class="device-info__value">
        {{ equipmentName }}
      </div>
      <button
        v-if="!equipmentName && !editNickName"
        class="hs-link device-info__add"
        @click.prevent="editNickName = true"
      >
        Add Nickname
      </button>
      <div v-if="editNickName" class="device-info__field">
        <hs-input
          :error="nicknameError"
          :readonly="blockEditing"
          type="bottom-border"
          class="device-info__input"
          v-model="equipmentName"
          @input="$emit('editingchanged', true)"
        />
        <hs-button :disabled="blockEditing" size="xs" @click="updateDevice">Save</hs-button>
      </div>
    </div>
    <div v-if="!showDesktop" class="device-info__column device-info__column--full">
      <div class="device-info__label">Serial Number</div>
      <div v-if="serialNumber && !editSerialNumber">
        <button
          v-if="serialNumber && serialNumberChangeable && !unverifiedDevice"
          class="device-info__value device-info__value--editable"
          @click="startEditSerialNumber"
        >
          {{ serialNumber }}
        </button>
        <div class="device-info__value" v-else>
          {{ serialNumber }}
        </div>
      </div>
      <button
        v-if="!serialNumber && serialNumberChangeable && !editSerialNumber"
        class="hs-link device-info__add"
        @click.prevent="editSerialNumber = true"
      >
        Add Serial Number
      </button>
      <div v-if="editSerialNumber && serialNumberChangeable" class="device-info__field">
        <hs-input
          :error="snError"
          :readonly="blockEditing"
          type="bottom-border"
          class="device-info__input"
          @input="$emit('editingchanged', true)"
          v-model="serialNumber"
        />
        <hs-button :disabled="blockEditing" size="xs" @click="updateDevice">Save</hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import DeviceIcon from '@/components/shared/DeviceIcon';
import {isInProgressStatus} from '@/constants/esb_claim_statuses';

export default {
  components: {
    DeviceIcon,
  },
  props: ['size', 'claims'],
  data() {
    return {
      showRemovePopup: false,
      isEmptySerialNumberPopupVisible: false,
      editNickName: false,
      editSerialNumber: false,
      vw: window.innerWidth,
      equipmentName: '',
      serialNumber: '',
      nicknameError: '',
      snError: '',
      blockEditing: false,
    };
  },
  computed: {
    ...mapGetters('myDevices', ['activeDevices']),
    ...mapState('deviceInformation', [
      'productLine',
      'productLineName',
      'model',
      'modelName',
      'createdDate',
      'equipmentStatus',
      'inventoryEquipmentId',
    ]),
    ...mapState('deviceInformation', {
      savedEquipmentName: 'equipmentName',
      savedSerialNumber: 'serialNumber',
    }),
    unverifiedDevice() {
      return this.equipmentStatus && this.equipmentStatus !== 'Active';
    },
    isAnyClaim() {
      return !!this.claims?.length;
    },
    serialNumberChangeable() {
      return !this.isAnyClaim;
    },
    showDesktop() {
      return this.vw >= 1024;
    },
    createdDateShow() {
      if (this.createdDate) {
        const dateInst = new Date(this?.createdDate);
        return `${dateInst.getMonth() + 1}/${dateInst.getDate()}/${dateInst.getFullYear()}`;
      }
      return '-';
    },
  },
  methods: {
    isNameUnique(name, id) {
      if (!name?.trim()) return true;
      if (this.activeDevices?.length) {
        const clearName = name?.trim()?.toLowerCase();
        if (!clearName) return true;
        for (const d of this.activeDevices) {
          if (d.equipmentName) {
            const clearNickname = d.equipmentName?.trim()?.toLowerCase();
            if (clearNickname === clearName && id !== d.inventoryEquipmentId) return false;
          }
        }
      }
      return true;
    },
    removePopup() {
      if (!this.isAnyClaim) {
        this.showRemovePopup = true;
      }
    },
    hideEmptySerialNumberPopup() {
      this.isEmptySerialNumberPopupVisible = false;
    },
    async confirmDelete() {
      const {params, query} = this.$route;
      const {id} = params;
      const {customerRef} = query;
      const {productLine} = this;
      this.$emit('editingchanged', false);
      const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
        customerRef,
      });
      await this.$store.dispatch('device/REMOVE_DEVICE', {
        id,
        productLine,
        customerRef: resp?.customerRef,
      });
      this.showRemovePopup = false;
      await this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    startEditNickname() {
      this.editSerialNumber = false;
      this.serialNumber = this.savedSerialNumber;
      this.editNickName = true;
    },
    startEditSerialNumber() {
      this.editNickName = false;
      this.equipmentName = this.savedEquipmentName;
      if (!this.isAnyClaim) {
        this.editSerialNumber = true;
      }
    },
    updateDevice() {
      this.nicknameError = '';
      this.snError = '';
      const {id} = this.$route.params;
      const nickname =
        this.equipmentName?.trim() !== this.savedEquipmentName?.trim()
          ? this.equipmentName?.trim()
          : this.savedEquipmentName?.trim();
      const sn =
        this.serialNumber?.trim() !== this.savedSerialNumber?.trim()
          ? this.serialNumber?.trim()
          : this.savedSerialNumber?.trim();
      if (!this.isNameUnique(nickname, this.inventoryEquipmentId)) {
        this.nicknameError = 'Nickname should be unique to your device';
        return;
      }
      if (sn.length) {
        this.blockEditing = true;
        this.$store
          .dispatch('deviceInformation/SAVE_DEVICE_INFO', {id, sn, nickname})
          .then((resp) => {
            this.blockEditing = false;
            if (
              resp?.data?.response?.updateEquipmentDetailsResponse?.errorDetails?.errorDetail
                ?.errorDescription === 'SerialNumber already exists'
            ) {
              this.snError = 'Serial Number already exists';
            } else {
              this.editSerialNumber = false;
              this.editNickName = false;

              this.serialNumber = sn;
              this.equipmentName = nickname;

              this.$store.commit('deviceInformation/UPDATE_SERIAL_NUMBER', sn);
              this.$store.commit('deviceInformation/UPDATE_NICKNAME', nickname);

              this.serialNumber = this.savedSerialNumber;
              this.equipmentName = this.savedEquipmentName;
            }
          });
        this.$emit('editingchanged', false);
      } else {
        this.isEmptySerialNumberPopupVisible = true;
      }
    },
    onResize() {
      this.vw = window.innerWidth;
    },
  },
  watch: {
    savedEquipmentName: {
      handler(newValue) {
        if (newValue) {
          this.equipmentName = newValue;
        }
      },
      immediate: true,
    },
    savedSerialNumber: {
      handler(newValue) {
        if (newValue) {
          this.serialNumber = newValue;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
.device-info {
  &__input {
    .vue-input__inner {
      height: 36px;
      font-size: 18px;
    }
  }
}
</style>

<style lang="scss" scoped>
.device-info {
  padding: 60px 0;
  &__label {
    margin-top: 31px;
    color: $black;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    &:first-child {
      margin-top: 0;
    }
  }
  &__value {
    margin-top: 6px;
    color: $darkGray;
    font-size: 18px;
    line-height: 24px;
    background: none;
    &--editable {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      cursor: pointer;

      @include mf($gridMDBreakpoint) {
        margin-top: 6px;
        padding-bottom: 1px;
      }
      &::after {
        display: block;
        flex: 1 0 34px;
        width: 34px;
        max-width: 34px;
        height: 34px;
        margin-left: auto;
        background: url(~@/assets/images/mobile-pencil.svg) no-repeat 50% 50% / contain;
        content: '';

        @include mf($gridMDBreakpoint) {
          flex: 1 0 15px;
          width: 15px;
          max-width: 15px;
          height: 15px;
          margin-left: 5px;
          background: url(~@/assets/images/pencil.svg) no-repeat 0 0/12px 12px;
        }
      }
    }
  }
  &__device-icon {
    width: 79px;
    height: 79px;

    @include mf($gridMDBreakpoint) {
      width: 99px;
      height: 99px;
    }
  }
  &__add {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 6px;
    line-height: 21px;
    &::before {
      display: block;
      width: 11px;
      height: 12px;
      margin-right: 5px;
      background: url(~@/assets/images/plus.svg) no-repeat 0 0/11px 12px;
      content: '';
    }
  }
  &__field {
    display: flex;
    align-items: flex-start;
  }
  &__input {
    flex: 1;
    margin-right: 9px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    @include mf($gridMDBreakpoint) {
      flex-wrap: nowrap;
    }
  }
  &__column {
    flex: 1;

    @include mf($gridMDBreakpoint) {
      padding-left: 70px;
    }
    &--full {
      flex: 1 0 100%;
      max-width: 100%;
      margin-top: 11px;
      padding-top: 16px;
      border-top: 1px solid #eceae6;

      @include mf($gridMDBreakpoint) {
        margin-top: 22px;
      }
      &:last-child {
        padding-bottom: 16px;
        border-bottom: 1px solid #eceae6;
      }
    }
    &--last {
      display: flex;
      flex: 1 0 100%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      max-width: 100%;

      @include mf($gridMDBreakpoint) {
        flex: inherit;
        max-width: inherit;
      }
    }
    &--first {
      flex: inherit;
      padding-right: 20px;
      padding-left: 0;

      @include mf($gridMDBreakpoint) {
        padding-right: 0;
      }
    }
  }
  &__date {
    padding-bottom: 25px;
    color: $mediumGray;
    font-size: 14px;
    line-height: 22px;
    text-align: right;

    @include mf($gridMDBreakpoint) {
      font-size: 16px;
    }
    span {
      display: block;
      margin-top: 5px;
    }
  }
}
</style>
<style lang="scss">
.hs-popup__footer--device {
  display: block;

  @include mf($gridMDBreakpoint) {
    display: flex;
  }
  .hs-btn {
    width: 100% !important;
    margin-top: 16px !important;

    @include mf($gridMDBreakpoint) {
      width: auto !important;
      margin-top: 0 !important;
    }
  }
}
</style>
